import { TextField } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import InputMask from 'react-input-mask/lib/react-input-mask.development'
import { BlocksContext } from "../context/blocksContext"
import { useSendForm } from '../hooks/send-from.hook'
import CountDown from './countdown'
import parse from 'html-react-parser';

export const Header = () => {
    const blocks = useContext(BlocksContext)
    const [state, setState] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [title, setTitle] = useState("<span>Квартиры <br /> с видом на море</span>")
    const sendForm = useSendForm()

    const showPopup = (e) => {
        e.preventDefault()
        console.log(blocks.blocks)
        if (blocks.blocks < 4) {
            blocks.setBlocks(24)
            let target = e.currentTarget
            setTimeout(() => {
                document.querySelector('body').classList.add('overflow')
                document.querySelector('.popup_rgba').style.display = "block"
                document.querySelectorAll('.popup_main').forEach(el => {
                    el.style.display = "none"
                });
                document.querySelector('.' + target.getAttribute('data')).style.display = "block"
            }, 1800)
        } else {
            document.querySelector('body').classList.add('overflow')
            document.querySelector('.popup_rgba').style.display = "block"
            document.querySelectorAll('.popup_main').forEach(el => {
                el.style.display = "none"
            });
            console.log(e.currentTarget.getAttribute('data'))
            document.querySelector('.' + e.currentTarget.getAttribute('data')).style.display = "block"
        }
    }

    useEffect(()=>{
        /*setTimeout(()=>{
            if (blocks.blocks < 4) {
                blocks.setBlocks(22)
                setTimeout(() => {
                    document.querySelector('body').classList.add('overflow')
                    document.querySelector('.popup_rgba').style.display = "block"
                    document.querySelectorAll('.popup_main').forEach(el => {
                        el.style.display = "none"
                    });
                    document.querySelector('.pu_vygoda').style.display = "block"
                }, 1800)
            } else {
                document.querySelector('body').classList.add('overflow')
                document.querySelector('.popup_rgba').style.display = "block"
                document.querySelectorAll('.popup_main').forEach(el => {
                    el.style.display = "none"
                });
                document.querySelector('.pu_vygoda').style.display = "block"
            }
        },20000)*/
    },[])

    const getTitle = () => {
        const headers = { 'Content-Type': 'application/json' }
        fetch("/titles.php"+window.location.search, headers)
            .then(res => res.json())
            .then((result)=>{
                console.log(result.title)
                setTitle(result.title)
            })
    }

    useEffect(()=>{
        getTitle()
    },[])

    return (
        <React.Fragment>
            <CountDown />
            <section className="header">
                <div className="cw_main">
                    <div className="hd_inner">
                        <h1>
                            {parse(title)}
                            <div className="dec1"><img src="/images/dec1.svg" /></div>
                            <div className="dec2"><img src="/images/dec2.svg" /></div>
                        </h1>
                        <div className="hd_loc">Владивосток</div>
                        {/* <div className="hd_info">комфорт  от <b>7,7</b> млн &#8381;</div> */}
                        {/* <a className="military_btn" onClick={showPopup} data="pu_military_ipot">Аккредитован по программе <br /> военной ипотеки</a> */}
                        <div className='winner'><img src="/images/winner.png" /></div>
                    </div>
                    <img src="/images/dom_rf_hd.jpg" className="dom_rf_hd" />
                </div>
                {/* pu action */}
                {/* <div className='pu_action__btn' onClick={showPopup} data="pu_action_main"><img src="/images/action_btn_text.jpg" /></div> */}
                <div className={!state?'pu_action':'pu_action pu_action__open'}>
                <div className='pu_action__btn' onClick={()=>{setState(!state)}}></div>
                    <div className='pu_action__img'><img src={loaded?"/images/pu_action_img.jpg":""} /></div>
                    <form className="pu_form_action">
                        <div className='pu_action__title'>Узнайте подробнее об условиях акции</div>
                        <div className='pu_form__in'>
                            <div className="form_in react_input_style">
                                <TextField name="name" label="Ваше имя"  className="pu_name"/>
                            </div>
                            <div className="form_in react_input_style">
                                <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                                    <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                                </InputMask>
                            </div>
                        </div>
                        <input type="hidden" class="text" value="Узнать подробнее об условиях акции"/>
                        <div className='align_center'>
                            <button className="pu_btn" onClick={sendForm.sendForm} celtype="getAction">Узнать подробнее</button>
                        </div>
                    </form>
                </div>
            </section>
        </React.Fragment>
    )
}
