import React, { useContext, useEffect } from 'react'
import { BlocksContext } from "../context/blocksContext"

export const Menu = () => {
    const blocks = useContext(BlocksContext)

    const showPopup = (e)=>{
        e.preventDefault()
        console.log(blocks.blocks)
        if (blocks.blocks<4) {
            blocks.setBlocks(23)
            let target = e.currentTarget
            setTimeout(()=>{
                document.querySelector('body').classList.add('overflow')
                document.querySelector('.popup_rgba').style.display="block"
                document.querySelectorAll('.popup_main').forEach(el => {
                    el.style.display="none"
                });
                document.querySelector('.'+target.getAttribute('data')).style.display="block"
            },1800)
        } else {
            document.querySelector('body').classList.add('overflow')
            document.querySelector('.popup_rgba').style.display="block"
            document.querySelectorAll('.popup_main').forEach(el => {
                el.style.display="none"
            });
            console.log(e.currentTarget.getAttribute('data'))
            document.querySelector('.'+e.currentTarget.getAttribute('data')).style.display="block"
        }
    }

    const handleScroll = (event)=>{
        
        let lastPosition = parseInt(document.querySelector('html').getAttribute('lastPosition'))
        if (lastPosition!=undefined) {
            if (lastPosition< window.scrollY+100) {
                //console.log('down')
                document.querySelectorAll('section').forEach((section) => {
                    if (window.scrollY + (window.innerHeight / 2.5) > section.offsetTop && !section.classList.contains('showed')) {
                        section.classList.add('showed')
                    }
                })
            } else {
                //console.log('up')
            }
        }
        document.querySelector('html').setAttribute('lastPosition', window.scrollY)
        
        let containerMain = document.querySelector('.container_main')
        if (!containerMain.classList.contains('scroll')) {
            containerMain.classList.add("scroll")
        }
    }

    
    useEffect(()=> {
        window.addEventListener('scroll', handleScroll, true);
        
    }, [])

    return (
        <section className="menu">
            <div className="cw_main">
                <logo><img src="images/logo_text.png" /></logo>
                <div className='menu_center'>
                    <div className="menu_btn"  onClick={showPopup} data="popup_menu"><span>Меню</span></div>
                    <div className='nemu_action' onClick={showPopup} data="pu_action_main">Акции</div>
                    <div className='nemu_action' onClick={showPopup} data="pu_action_oplata">Способы оплаты</div>
                </div>
                <div className="menu_left">
                    <a className="call_phone roistat-phone" href="tel:+74958741249">8 (495) 874-12-49</a>
                    <button className="btn_main call_btn"  onClick={showPopup} data="pu_call"><span>Заказать звонок</span></button>
                </div>
            </div>
		</section>
    )
}