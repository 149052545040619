import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { BlocksContext } from "../context/blocksContext";

export const CountDown = () => {
    const [isOpen, setModalState] = useState(false)
    const blocks = useContext(BlocksContext)
    const [time, setTime] = useState()
    const endDate = Date.parse("01 Jan 2023 00:00:00 GMT+0300")
    const countTime = () => {
        let now = new Date()
        if (endDate < now) {
            setTime(false)
            return false
        }
        var diff = Math.abs(endDate - now);
        setTime(dhm(diff))
        return true
    }

    const dhm = (t) => {
        var cd = 24 * 60 * 60 * 1000,
            ch = 60 * 60 * 1000,
            d = Math.floor(t / cd),
            h = Math.floor( (t - d * cd) / ch),
            m = Math.round( (t - d * cd - h * ch) / 60000),
            pad = function(n){ return n < 10 ? '0' + n : n; };
      if( m === 60 ){
        h++;
        m = 0;
      }
      if( h === 24 ){
        d++;
        h = 0;
      }
      return [d, pad(h), pad(m)].join(':');
    }
    
    useEffect(()=>{
        countTime()
        let interval = setInterval(()=>{
            if (!countTime()) {
                clearInterval(interval);
            }
        },1000)
    },[])

    const showPopup = (e) => {
        e.preventDefault()
        console.log(blocks.blocks)
        if (blocks.blocks < 4) {
            blocks.setBlocks(19)
            let target = e.currentTarget
            setTimeout(() => {
                document.querySelector('body').classList.add('overflow')
                document.querySelector('.popup_rgba').style.display = "block"
                document.querySelectorAll('.popup_main').forEach(el => {
                    el.style.display = "none"
                });
                document.querySelector('.' + target.getAttribute('data')).style.display = "block"
            }, 1800)
        } else {
            document.querySelector('body').classList.add('overflow')
            document.querySelector('.popup_rgba').style.display = "block"
            document.querySelectorAll('.popup_main').forEach(el => {
                el.style.display = "none"
            });
            console.log(e.currentTarget.getAttribute('data'))
            document.querySelector('.' + e.currentTarget.getAttribute('data')).style.display = "block"
        }
    }

    return ( 
        <React.Fragment>
            {time?<div className='countdown'>
                <div className='countdown__title'>
                    До окончания льготной ипотеки осталось...
                </div>
                <div className='countdown__num'>
                    {time}
                </div>
                <div className='countdown__lab'>
                    <div>дней</div>
                    <div>часов</div>
                    <div>минут</div>
                </div>
                <div className='countdown__btn' onClick={showPopup} data="pu_countdown">
                    <span>Подать заявку</span>
                </div>
            </div>:""}
        </React.Fragment>
    )
}

export default CountDown